import React from 'react';
import Layout from '../components/layout';
import PageContainer from '../components/Containers/PageContainer';
import Header from '../components/Header';
import { Grid, makeStyles } from '@material-ui/core';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import SIZES from '../constants/sizes';
import COLORS from '../constants/colors';
import {
  FormattedMessage,
  navigate,
} from 'gatsby-plugin-intl';
import PageSection from '../components/Cards/PageSection';
import DefaultButton from '../components/Buttons/DefaultButton';
import SEO from '../components/seo';

const useStyles = makeStyles(theme => ({
  title: {
    color: COLORS.DARK_BLUE,
    paddingBottom: 40,
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
  buttonContainer: {
    paddingTop: 98,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
  },
}));

const Services = ({ data }) => {
  const classes = useStyles();
  let isActualPagePostReversed = false;

  return (
    <Layout>
      <SEO
        title="Scanship | Services"
        description="Latest news from Scanship"
        keywords={['Services']}
      />
      <PageContainer>
        <Grid container>
          <Grid item xs={12}>
            <Header
              sub
              textTransform="lowerCase"
              className={classes.title}
            >
              <FormattedMessage id="services.title" />
            </Header>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {data.allContentfulScanshipPageSection.edges.map(
                (
                  {
                    node: {
                      image: { fluid },
                      title,
                      description: { description },
                    },
                  },
                  i
                ) => {
                  isActualPagePostReversed = !isActualPagePostReversed;
                  return (
                    <PageSection
                      key={title}
                      imgFluid={fluid}
                      title={title && title}
                      description={description}
                      reversed={isActualPagePostReversed}
                      alignText="center"
                      paddingTop={i !== 0}
                      paddingBottom={
                        i !==
                        data
                          .allContentfulScanshipPageSection
                          .edges.length -
                          1
                      }
                    />
                  );
                }
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.buttonContainer}
          >
            <DefaultButton
              onClick={() => navigate('/contact')}
            >
              <FormattedMessage id="services.button" />
            </DefaultButton>
          </Grid>
        </Grid>
      </PageContainer>
    </Layout>
  );
};

Services.propTypes = {
  data: shape({}).isRequired,
};

export default Services;

export const query = graphql`
  query ServicesQuery {
    allContentfulScanshipPageSection(
      filter: { page: { eq: "/services" } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          title
          description {
            description
          }
        }
      }
    }
  }
`;
